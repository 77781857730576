import React from "react";

import { useSiteData } from "utils";

const SiteIcon = ({ className, image, path, style }) => {
  const { pages } = useSiteData();

  if (path) {
    image = path.split(".").reduce((d, k) => (d || {})[k], pages);
  }

  return image ? (
    <img
      className={`site-image ${className || ""}`}
      style={style}
      src={`${image}`}
    />
  ) : (
    ""
  );
};

export default SiteIcon;
