import styled from "styled-components";

import vars from "variables";

import heroMobile from "assets/PlayToEarn/mobile-hero.png";
import fullHeroBackground from "assets/PlayToEarn/full-hero-background.png";

import topRightBlue from "assets/Exchanges/topright-blue.svg";

const Root = styled.div`
  div.mini-game {
    position: relative;
    background: ${vars.colors.marineBlue};
    border-radius: 30px;
    border: 2px solid;
    border-color: #00c9ff;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem;
    margin: 5rem 0;
  }

  span.hero-cta {
    text-transform: uppercase;
  }

  span.info {
    opacity: 100%;
    position: relative;
    margin: 1rem 0;
    padding: 12px 37px 12px 15px;
    display: block;
    font-weight: 100;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.6px;
  }

  h3.coming-soon-section__header {
    margin-bottom: -4.063rem !important;
  }

  .coming-soon-section__info-box {
    padding-top: 2.5rem;
  }

  .hero-info-box__info-icon {
    margin-right: 0.5rem;
  }

  .hero-cta-wrapper__hero-cta {
    margin: 1rem 1rem 1rem 0;
    width: 150px;
    text-align: center;
  }

  .hero-background {
    position: absolute;
    top: 0;
    right: 750px;
    z-index: 0;
  }

  .hero-box p {
    margin: 1.5rem 0 1rem 0;
  }

  .hero-box p,
  .site-data p {
    color: #c7c7c7;
    letter-spacing: 0.393636px;
    line-height: 215%;
    font-weight: 300;
  }

  .hero-intro {
    height: 65rem;
  }

  .hero-background-container {
    position: absolute;
    z-index: -1;
  }

  .hodl-icon {
    margin-right: 5px;
  }

  .hero-info-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }

  h2.mini-game-title {
    font-size: 2.9rem;
    margin-bottom: 0.9rem;
  }

  p.mini-game-desc {
    font-weight: 300;
    letter-spacing: 0.363636px;
    color: ${vars.colors.white} !important;
  }

  .mini-game-subtitle {
    display: flex;
    align-items: center;
    letter-spacing: 0.363636px;
    justify-content: space-between;
    max-width: fit-content;
    margin-bottom: 1rem;
  }

  .mini-game-play-box {
    display: flex;
    align-items: center;
  }

  .mini-game-desc {
    width: 400px;
    line-height: 185%;
    display: block;
    margin-bottom: 2rem;
  }

  .mini-game-thumbnail {
    width: 215px;
    height: 215px;
  }

  .section-border {
    border-top: solid 1px transparent;
    border-image: linear-gradient(
        90deg,
        rgba(197, 195, 198, 0.0001) 0%,
        #657c8d 50.45%,
        rgba(197, 195, 198, 0.0001) 100%
      )
      1;
  }

  .mini-game-play-cta {
    margin-right: 1.5rem;
    padding: 23px 40px;
  }

  .play-to-earn-cta {
    margin-right: 1rem;
  }

  .mini-game-thumbnail {
    position: absolute;
    bottom: 50px;
    right: 50px;
  }

  .links a {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    background: url(${topRightBlue}) no-repeat center right;
  }

  .info-box {
    line-height: 20px;
    position: absolute;
  }

  .info-box a {
    padding: 6px 30px 6px 12px;
    font-size: 0.7rem;
    background-size: 14px;
  }

  ${vars.desktop.mediaQuery} {
    .hero-background-container {
      top: 0;
      left: 50%;
      width: 170rem;
      height: 63.9rem;
      margin-left: -52rem;
      background-image: url(${fullHeroBackground});
      background-repeat: no-repeat;
      background-position: left bottom;
      border-bottom: 1px solid transparent;
      border-image: linear-gradient(
          90deg,
          rgba(197, 195, 198, 0) 0%,
          rgb(101, 124, 141) 50.45%,
          rgba(197, 195, 198, 0) 100%
        )
        1 / 1 / 0 stretch;
    }

    .hero-box {
      padding-top: 11rem;
    }

    .hero-box p {
      margin: 1rem 0;
    }

    .hero-intro {
      height: 55rem;
    }

    .scaled & {
      margin-top: -5.3rem;
    }

    .scaled & .hero-intro {
      height: 62.5rem;
    }

    .hero-info-box {
      padding: 3.5rem 0;
    }

    .hero-intro__hero-box-wrapper {
      position: absolute;
      top: 13rem;
    }

    .mini-game-play-cta {
      margin-right: 1rem;
    }

    .mini-game-logo {
      width: 32px;
      height: 32px;
      margin-right: 0.4rem;
    }

    .info {
      padding: 60px 70px 45px 75px;
    }

    .info-box {
      right: 60px;
    }

    h3 {
      font-size: 1.7rem;
      font-weight: 600;
    }

    p {
      color: ${vars.colors.grey};
      font-size: 1rem;
      line-height: 150%;
    }

    .links a {
      margin-top: 28px;
      margin-right: 45px;
      padding-right: 22px;
      font-size: 0.75rem;
    }

    .mini-game-mobile {
      background: none !important;
    }

    div.mini-game {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }

  ${vars.mobile.mediaQuery} {
    a.more {
      display: none !important;
    }

    .coming-soon-section__info-box h3.coming-soon-section__header {
      margin-bottom: 0 !important;
    }

    span.info {
      ::before {
        top: unset;
        left: unset;
      }
    }

    .hero-box {
      text-align: center;
    }

    .hero-box h1 {
      font-size: 2rem;
    }

    .hero-box p {
      margin: 0.7rem 0 2rem 0;
    }

    .hero-intro__hero-box-wrapper {
      position: unset;
    }

    .hero-cta-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .hero-cta-wrapper__hero-cta {
      margin: 0.7rem 0;
      padding: 15px 3px;
    }

    .hero-intro {
      margin-top: 2.75rem;
      height: 41rem;
    }

    .hero-background-container {
      background-image: url(${heroMobile});
      background-position: bottom;
      background-repeat: no-repeat;
      width: 100vw;
      height: 45rem;
      z-index: -1;
    }

    .hero-info-box__info-icon {
      margin-right: 0.5rem;
    }

    .mini-game-section {
      margin-bottom: 7rem;
      border-top: 1px solid transparent;
      border-image: linear-gradient(
          90deg,
          rgba(197, 195, 198, 0) 0%,
          rgb(101, 124, 141) 50.45%,
          rgba(197, 195, 198, 0) 100%
        )
        1 / 1 / 0 stretch;
    }

    .mini-game-section__header,
    .coming-soon-section__header {
      width: 20rem;
      margin: auto;
    }

    .mini-game-mobile {
      background-repeat: no-repeat;
      background-position: bottom;
      background-color: ${vars.colors.marineBlue};
      width: 20rem;
      margin: auto;
      border-radius: 10px;
    }

    .mini-game-logo {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
      margin-top: 0;
      margin-bottom: 0;
    }

    .link {
      display: none;
    }

    .hodlx-icon {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
    }

    div.mini-game {
      position: relative;
      align-items: center;
      padding: 2rem;
      margin: 1rem 0 4rem;
      border-radius: 10px;
      background: none !important;
    }

    .mini-game-subtitle {
      margin: auto;
    }

    div.reverse {
      flex-direction: column-reverse;
    }

    .site-section {
      display: flex;
      flex-direction: column-reverse;
      width: 20rem;
    }

    .site-section__image {
      margin-top: 0;
      margin-bottom: 3rem;
    }

    .infoBox {
      border: none !important;
      margin-left: 1.5rem;
    }

    span.title {
      font-size: 0.64rem !important;
    }

    span.subtitle {
      font-size: 0.64rem !important;
    }

    p.mini-game-desc {
      width: fit-content !important;
      margin-bottom: 0 !important;
      padding: 1rem;
      text-align: center;
    }

    .mini-game-play-box {
      flex-direction: column;
      align-items: center;
      height: fit-content;
      justify-content: space-evenly;
    }

    h2.mini-game-title {
      font-size: 1.2rem;
      font-weight: 600;
      text-align: center;
    }

    .mini-game-play-cta {
      padding: 18px 45px;
      margin-right: 0;
      margin-bottom: 1rem;
    }

    .play-to-earn-box {
      margin-bottom: 1rem;
    }

    .play-to-earn-cta {
      width: 32px;
    }

    .play-to-earn-info {
      text-align: left;
    }

    .mini-game-thumbnail {
      position: unset;
    }

    .info {
      padding: 13px 20px;
    }

    .info-box {
      right: 25px;
      bottom: 12px;
    }

    .coming-soon-section__info-box {
      margin-bottom: 0.75rem;
      padding: 2rem 0 0 0;
    }

    h3 {
      padding-bottom: 12px;
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 140%;
    }

    p {
      margin-bottom: 15px;
    }

    img.logo {
      max-height: 35px;
      margin-top: -75px;
    }

    .links a {
      margin: 5px 25px 13px 0;
      padding-right: 12px;
      font-size: 0.6rem;
      background-size: 6px;
      background-position: top 6px right;
    }
  }
`;

export { Root };
