import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";

import playToEarnActiveImage from "assets/PlayToEarn/fist-active.png";
import playToEarnInactiveImage from "assets/PlayToEarn/fist-inactive.png";

const PlayToEarn = ({ className, toggle, image, path, style }) => {
  const { pages } = useSiteData();

  if (path) {
    image = path.split(".").reduce((d, k) => (d || {})[k], pages);
  }

  return (
    <Root>
      {toggle ? (
        <div className="play-to-earn-box">
          <img
            className={`site-image ${className || ""}`}
            style={style}
            src={`${playToEarnActiveImage}`}
          />
          <div className="play-to-earn-info">
            <span className="title">play to earn</span>
            <span className="subtitle">Active</span>
          </div>
        </div>
      ) : (
        <div className="play-to-earn-box">
          <img
            className={`site-image ${className || ""}`}
            style={style}
            src={`${playToEarnInactiveImage}`}
          />
          <div className="play-to-earn-info">
            <span className="title">play to earn</span>
            <span className="subtitle">Coming soon</span>
          </div>
        </div>
      )}
    </Root>
  );
};

export default PlayToEarn;
