import styled from "styled-components";

let fontSize = '12px';

const Root = styled.div`
  .play-to-earn-box {
    display: flex;
    align-items: center;
  }
  .play-to-earn-info {
    letter-spacing: 0.254545px;
    line-height: 1rem;
  }
  .title {
    text-transform: uppercase;
    font-weight: 900;
    font-size: ${fontSize};
    display: block
  }
  .subtitle {
    text-transform: capitalize;
    font-weight: 700;
    font-size: ${fontSize};
    line-height: 75%;
  }
`;

export { Root };