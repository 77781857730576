import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  .video {
    width: 100%;
  }

  .faqs {
    text-align: center;
  }

  .faqs p {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 60px;
  }

  .page-header body {
    display: inline;
  }

  ${vars.desktop.mediaQuery} {
    .video {
      margin-top: 120px;
      margin-bottom: -30px;
    }

    .faqs h2 {
      font-size: 3.5rem;
    }

    .faqs p {
      width: 50%;
      color: ${vars.colors.grey};
      line-height: 165%;
    }
  }

  ${vars.mobile.mediaQuery} {
    .video {
      margin-top: 50px;
      margin-bottom: 40px;
    }

    .faqs h2 {
      font-size: 1.25rem;
      font-weight: 700;
    }

    .faqs p {
      width: calc(100vw - 40px);
      margin-bottom: 40px;
    }

    a.button {
      padding-left: auto;
      padding-right: auto;
    }

    .site-section {
      margin-top: 35px;
    }

    .site-section img {
      display: none;
    }

    .ready-to-start {
      margin-bottom: 75px;
    }
  }
`;

export { Root };
