import React, { Fragment } from 'react';
import { Root } from './style';

import { useSiteData } from 'utils';
import SiteData from 'components/Common/SiteData';
import SiteImage from 'components/Common/SiteImage';
import SiteIcon from 'components/Common/SiteIcon';
import SiteSection from 'components/Common/SiteSection';
import PlayToEarn from './PlayToEarn';
import NFTs from './NFTs';

import info from 'assets/Common/InfoBox/info.svg';
import hodlIcon from 'assets/Common/Logo/hodl-icon.svg';
import hodlxIcon from 'assets/Common/Logo/hodlx.png';
import hodlHands from 'assets/PlayToEarn/hodl-round.png';
import gemFighter from 'assets/PlayToEarn/gf-round.png';

import vars from 'variables';

import Connect from '../Layout/Header/Menu/NavBar/Connect';
import { useWeb3 } from 'web3';
import Wallet from '../Layout/Header/Menu/NavBar/Wallet';

const Games = ({ pageContext: { miniGames } }) => {
  const { games: page } = useSiteData().pages;

  const { wallet } = useWeb3();

  return (
    <Root>
      <div className="hero-background-container" />
      <section className="body-width hero-intro">
        <div className="hero-intro__hero-box-wrapper">
          <SiteData path="games.heroSection" className="hero-box" />
          <div className="hero-cta-wrapper">
            <a
              className="button blue hero-cta-wrapper__hero-cta"
              href="#mini-games"
              target="_self"
              rel="noreferrer"
            >
              <span className="hero-cta">{page.heroCtaLeft}</span>
            </a>
            <a
              className="button blue hero-cta-wrapper__hero-cta"
              href={vars.links.hodlhandsnfts}
              target="_blank"
              rel="noreferrer"
            >
              <span className="hero-cta">{page.heroCtaRight}</span>
            </a>
            {wallet ? <Wallet /> : <Connect />}
          </div>
        </div>
      </section>
      <section className="body-width mini-game-section" id="mini-games">
        <div className="hero-info-box">
          <SiteIcon className="hero-info-box__info-icon" image={info} />
          {page.infoText}
        </div>
        <h3 className="mini-game-section__header">mini-games</h3>
        <div>
          {miniGames.map((miniGame) => (
            <div
              key={miniGame.id}
              className="mini-game-mobile"
              style={{
                backgroundImage: `url(${vars.assetsPrefix}/${miniGame.mobileBackground})`,
              }}
            >
              <div
                className="mini-game"
                style={{
                  backgroundImage: `url(${vars.assetsPrefix}/${miniGame.background})`,
                }}
              >
                <h2 className="mini-game-title">{miniGame.title}</h2>
                <div className="mini-game-subtitle">
                  {miniGame.earnHODL && (
                    <SiteIcon className="mini-game-logo" image={hodlIcon} />
                  )}
                  {miniGame.earnHODLX && (
                    <SiteIcon className="mini-game-logo" image={hodlxIcon} />
                  )}
                  {miniGame.earnHH && (
                    <SiteIcon className="mini-game-logo" image={hodlHands} />
                  )}
                  {miniGame.earnGF && (
                    <SiteIcon className="mini-game-logo" image={gemFighter} />
                  )}
                  <strong>{miniGame.subtitle}</strong>
                </div>
                <p className="mini-game-desc">{miniGame.description}</p>
                <div className="mini-game-play-box">
                  <a
                    className="button blue mini-game-play-cta"
                    href={miniGame.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PLAY NOW
                  </a>
                  <PlayToEarn
                    className="play-to-earn-cta"
                    toggle={miniGame.playToEarnActive}
                  />
                  <SiteImage
                    className="mini-game-thumbnail"
                    image={miniGame.thumbnail}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="body-width coming-soon-section section-border">
        <div className="coming-soon-section__info-box">
          <h3 className="coming-soon-section__header">coming soon...</h3>
        </div>
        <SiteSection
          imageClass="site-section__image"
          className="coming-soon-game image"
          path="games.codeSection1"
          image="imageSection1"
        />
        <SiteSection
          imageClass="site-section__image"
          className="coming-soon-game image"
          path="games.codeSection2"
          image="imageSection2"
          reverse={true}
        />
      </section>
      <section className="section-border">
        <NFTs />
      </section>
    </Root>
  );
};

export default Games;
