import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";
import { Link } from "gatsby";
import capitalize from "lodash/capitalize";

import RawHTML from "components/Common/RawHTML";

import vars from "variables";

const P2EHoldingNFTs = ({ showMore }) => {
  const {
    resources: {
      banners: { p2eHoldingNfts },
    },
    pages: { games: page },
  } = useSiteData();

  let subtitle, match;

  if (showMore) {
    subtitle = page.nftsSubtitle;
    match = ("" + subtitle).match(/^\[([A-Z]+)\] (.*)/);
  }

  return (
    <Root className={showMore ? "show-more" : ""}>
      <div className="body-width">
        {subtitle ? (
          match ? (
            <div className="highlight">
              <strong>{match[1]}</strong>
              <RawHTML
                html={match[2].replace(
                  /(https?:\/\/([^\/]+)\S+)/,
                  (_, href, domain) =>
                    `<a href="${href}" target="_blank" rel="noreferrer">${capitalize(
                      domain
                    )}</a>`
                )}
              />
            </div>
          ) : (
            <h4>
              <span>{subtitle}</span>
            </h4>
          )
        ) : (
          ""
        )}
        <RawHTML html={p2eHoldingNfts} />

        <Link className="more" to={vars.links.hodlhandsnfts}>
          Learn more
        </Link>
      </div>
    </Root>
  );
};

export default P2EHoldingNFTs;
