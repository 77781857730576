import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";

import FAQs from "components/Common/FAQs";
import P2EHoldingNFTs from "../P2EHoldingNFTs";

const NFTs = () => {
  const {
    games: { faqs, faqsTitle, faqsIntro, showLink, showMore },
  } = useSiteData().pages;

  return (
    <Root>
      <P2EHoldingNFTs showMore={showMore} showMoreLink={showLink}/>
      <div className="faqs">
        <h2>{faqsTitle}</h2>
        <p>{faqsIntro}</p>
      </div>
      <FAQs faqs={faqs} />
    </Root>
  );
};

export default NFTs;
