import styled from 'styled-components';

import vars from 'variables';

import hodlHands from 'assets/Common/HoldingNFTs/hodl-hands.png';
import topRight from 'assets/Common/HoldingNFTs/top-right.svg';

const Root = styled.div`
  text-align: center;
  background: url(${hodlHands}) no-repeat;

  .highlight {
    margin-top: 15px;
    padding: 2px;
    line-height: 200%;
    border-radius: 25px;
    background: rgb(0, 42, 84);
  }

  .highlight strong {
    padding: 0 10px;
    color: white;
    border-radius: 50px;
    background: ${vars.colors.blue};
  }

  .highlight a {
    display: inline-block;
    text-decoration: underline;
    background: url(${topRight}) no-repeat center right;
  }

  p {
    padding-top: 12px;
    color: rgb(154, 155, 159);
  }

  a.button,
  a.more {
    margin: 0 auto;
  }

  a.more {
    margin-top: 25px;
    display: table;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-bottom: 1px solid ${vars.colors.blue};
  }

  ${vars.desktop.mediaQuery} {
    & {
      margin-bottom: 135px;
      padding: 65px 0 50px;
      background-size: auto 580px;
      background-position: top 200px center;
    }

    &.show-more {
      background-position: top 300px center;
    }

    .highlight {
      margin-bottom: 40px;
      display: inline-flex;
      font-size: 0.9rem;
    }

    .highlight > div {
      padding: 0 15px;
    }

    .highlight a {
      padding-right: 15px;
    }

    h2 {
      font-size: 3.5rem;
    }

    p {
      padding-bottom: 590px;
      line-height: 165%;
    }

    &.show-more p {
      padding-bottom: 610px;
    }

    a.more {
      margin-bottom: 20px;
      font-size: 0.825rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-bottom: 65px;
      padding: 50px 0;
      background-size: auto 345px;
      background-position: top 147px center;
    }

    &.show-more {
      padding: 45px 0 35px;
      background-position: top 200px center;
    }

    .highlight {
      margin-bottom: 27px;
      display: inline-flex;
    }

    .highlight strong {
      margin-right: 8px;
      padding-top: 1px;
    }

    .highlight a {
      padding-right: 9px;
      background-size: 5px;
    }

    .highlight .raw-html {
      padding-top: 1px;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: 700;
    }

    p {
      padding-bottom: 375px;
    }

    a.more {
      margin-bottom: 20px;
    }

    br {
      display: none;
    }
  }
`;

export { Root };
