import React from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';

const FAQs = ({ faqs }) => {
  const state = useState(0);
  const questions = [];
  let currentQuestion;

  faqs.split(/\n/).forEach(line => {
    if (line.match(/^\s*---\s*$/)) {
      currentQuestion = null;

    } else if (!currentQuestion) {
      currentQuestion = {id: questions.length, question: line, answer: ''};
      questions.push(currentQuestion);

    } else {
      currentQuestion.answer += (currentQuestion.answer ? '\n' : '') + line;
    }
  });

  return (
    <Root className="body-width">
      <ul>
      {questions.map((question, index) =>
        <li key={index}
            className={state.get() === question.id ? 'active' : ''}
            onClick={() => state.set(question.id)}>
          <strong>{question.question}</strong>
          <p>{question.answer}</p>
        </li>
      )}
      </ul>
    </Root>
  );
};

export default FAQs;
